.owl-height {
  height: auto !important;
}

.banner__main {
  min-height: 700px;
  display: flex;
  align-items: center;
  position: relative;
}

.banner__video {
  height: 100%;
  position: absolute;
  width: 100%;
  object-fit: cover;
}

.banner__video__filter {
  height: 100%;
  position: absolute;
  width: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.35);
}

.category-box-style-02 ul.category-list li span {
  color: #ffffff;
  font-family: "Archivo", sans-serif;
}

.category-list span {
  color: #fff;
}

.headerImg {
  background: url("./assets/images/header.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.headerImgAlt {
  background: url("./assets/images/faq.jpg");
  background-position: center -150px;
  background-size: cover;
  background-repeat: no-repeat;
}

.coverBlack {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.35);
}

.industriesExp {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 18px;
  color: #022d62;
  padding-bottom: 35px;
  border-bottom: 1px solid #eeeeee;
}

@media screen and (max-width: 1200px) {
  .headerImgAlt {
    background: url("./assets/images/faq.jpg");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.loadingCon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.comingBg {
  background-image: url(./assets/images/new1.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px #000000d0;
}
.comingBgAlt {
  background-image: url(./assets/images/new2.jpeg);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px #000000d0;
}

@media screen and (max-width: 768px) {
  .gapSmall {
    margin-top: 80px;
  }
}

.blogBg {
  background-image: url(./assets/images/blog.jpg);
  background-position: center;
}

.linkPort {
  transition: 0.3s;
}
.linkPort:hover {
  color: #ef3139;
}

.pageLoading {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  top: 0;
  left: 0;
}
.pageLoading2 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 190;
  top: 0;
  left: 0;
}
