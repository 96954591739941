.portfolio__product {
  padding-top: 80px;
  /* padding-bottom: 100px; */
}
.portfolio__product h2 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.portfolio__product p {
  font-size: 18px;
  margin-bottom: 30px;
}

.portfolio__product__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.portfolio__product__list.alt {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.portfolio__product__list__item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* object-fit: cover; */
}

.portfolio__product__list__item img {
  width: 100%;
  /* max-height: 400px; */
  /* object-fit: cover; */
  padding-bottom: 10px;
}

/* .portfolio__product__list__item__img {
} */

.portfolio__product__list__item h3 {
  font-size: 16px;
  border-bottom: 1px solid #ef3139;
  padding-bottom: 15px;
}

@media screen and (max-width: 950px) {
  .portfolio__product__list {
    grid-template-columns: 1fr 1fr;
  }
  .portfolio__product__list.alt {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 750px) {
  .portfolio__product__list {
    grid-template-columns: 1fr;
  }
  .portfolio__product__list.alt {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .portfolio__product__list.alt {
    grid-template-columns: 1fr 1fr;
  }
}
